<template>
  <section class="events__area pt-60 pb-120 p-relative">
    <div class="events__shape">
      <img
        class="events-1-shape"
        src="../../assets/img/events/events-shape.webp"
        alt=""
      />
    </div>
    <div class="container">
      <div class="row">
        <div class="col-xxl-4 offset-xxl-4">
          <div class="section__title-wrapper mb-60 text-center">
            <h2 class="section__title">
              {{ $t("Current Events") }}
              <!-- <span class="yellow-bg yellow-bg-big">
                {{ $t("Events")
                }}<img src="../../assets/img/shape/yellow-bg.webp" alt=""
              /></span> -->
            </h2>
            <p>
              {{ $t("We found 4 events available for you.") }}
            </p>
          </div>
        </div>
      </div>
      <div class="row">
        <div
          v-for="event in EventData"
          :key="event.id"
          class="col-xxl-10 offset-xxl-1 col-xl-10 offset-xl-1 col-lg-10 offset-lg-1"
        >
          <div class="events__item mb-10 hover__active">
            <div
              class="events__item-inner d-sm-flex align-items-center justify-content-between white-bg"
            >
              <div class="events__content">
                <div class="events__meta">
                  <span>{{ event.date }}</span>
                  <span>{{ event.time }}</span>
                  <span>{{ event.city }}</span>
                </div>
                <h3 class="events__title">
                  <a :href="event.path" target="_blank">{{ event.title }} </a>
                </h3>
              </div>
              <div class="events__more">
                <a :href="event.path" target="_blank" class="link-btn">
                  {{ $t("View More") }}
                  <i class="far fa-arrow-right"></i>
                  <i class="far fa-arrow-right"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "EventArea",
  data() {
    return {
      EventData: [
        {
          id: 1,
          title: "Robotics, AI and Applications (WSERAA)",
          date: "Oct 17-19, 2024",
          time: "",
          city: "Bern, Switzerland",
          path: "https://roboticssummit2024.com/",
        },
        {
          id: 2,
          title: "Soft Computing, AI and Applications (ICSCAIA)",
          date: "Oct 18-19, 2024",
          time: "",
          city: "Rome, Italy",
          path: "https://waset.org/soft-computing-artificial-intelligence-and-applications-conference-in-october-2024-in-rome",
        },
        {
          id: 3,
          title: "AI in Medical Applications (ICAIMA)",
          date: "Oct 21-22, 2024",
          time: "",
          city: "London, UK",
          path: "https://waset.org/artificial-intelligence-in-medical-applications-conference-in-october-2024-in-london",
        },
        {
          id: 4,
          title: "AI and Technology (ICAIT)",
          date: "Oct 21-22, 2024",
          time: "",
          city: "Athens, Greece",
          path: "https://waset.org/artificial-intelligence-and-technology-conference-in-october-2024-in-athens  ",
        },
      ],
    };
  },
};
</script>
